import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import GetStarted from 'components/GetStarted';
import Meta from 'components/Meta';
import 'styles/about.scss';
// import { Main } from 'styles/about';

const About = ({ location }) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="About 📄 | ruttl"
          description="ruttl is a visual website feedback and reviewing tool that provides design teams the best way to leave comments and edit live websites for perfect designs."
          url="https://ruttl.com/about/"
        />
        <main className="about-styled-main">
          <section className="about-hero">
            <div className="container">
              <h1 style={{ maxWidth: '750px' }}>
                We are here to help you get your designs implemented on codes
                pixel perfectly
              </h1>
              <div className="flex align-items-center flex-wrap">
                <div className="about-single">
                  <img
                    src="/assets/img/ruttl_brain_illustration.jpg"
                    alt="Brain illustration for about us page on ruttl"
                  />
                </div>
                <div className="about-text">
                  <p>
                    In conventional website review, clients take screenshots,
                    encircle the issues, and share a Word doc with the developer
                    or project manager. Alternatively, they sit with the
                    developers and ask them to make the changes, but these
                    processes are very tedious and time-taking. On an average, a
                    single page takes around 3-4 hours to review and still, the
                    final output is just close to perfection.
                  </p>
                  <p>
                    That’s why we created ruttl. ruttl lets you make edits to
                    live websites and share contextual, visual feedback with
                    your team members. It also allows you to leave comments on
                    live website. ruttl has a lot of competitive advantages,
                    which you can check here. Built by designers &amp;
                    developers, ruttl is the future of design review. ruttl has
                    a lot of competitive advantages,&nbsp;
                    <Link to="/alternatives/">check here.</Link>
                  </p>
                  <Link className="button" to="/blog/why-ruttl-was-built/">
                    ruttl’s origin story
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 512 512">
                      <path
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="48"
                        d="M268 112l144 144-144 144M392 256H100"></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="ruttl-team">
            <div className="container">
              <div className="flex flex-wrap">
                <div className="team-image-block">
                  <h2>The team behind ruttl</h2>
                  <div className="team-mem">
                    <div className="team-logo">
                      <img src="/assets/img/brucira-logo.svg" alt="Brucira" />
                    </div>
                    <div className="mem-img">
                      <video
                        src="/assets/video/team-members.mp4"
                        autoPlay
                        loop
                        muted
                        poster="/assets/img/team-members.jpg"
                        playsInline></video>
                    </div>
                  </div>
                </div>
                <div className="team-text-block">
                  <p>
                    <Link to="/">ruttl</Link>
                    &nbsp;is the brainchild of designers &amp; developers over
                    at Brucira who were frustrated with the clunky,
                    time-consuming nature of the old design review process. As
                    the idea for a design review tool grew, we’ve added more
                    features from our beta users’ suggestions and our audience
                    interactions. In fact, we’ve coded &amp; reviewed ruttl on
                    ruttl — talk about a ruttlception!!
                  </p>
                  <p>
                    Over at Brucira, a product design agency, our designers
                    &amp; developers work across website designs, illustrations,
                    mobile apps, and so much more. So, we know the value of a
                    useful design review tool. We built ruttl to be an internal
                    tool and having seen its success at Brucira, we’re excited
                    to share it with other creators!
                  </p>
                  <Link
                    target="_blank"
                    className="button"
                    to="https://www.linkedin.com/company/ruttl/">
                    <img src="/assets/img/linkedin-white.svg" alt="linkedin" />
                    Follow ruttl on Linkedin
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 512 512">
                      <path
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="48"
                        d="M268 112l144 144-144 144M392 256H100"></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <GetStarted toggleSignup={toggleSignup} />
        </main>
      </>
    )}
  </Layout>
);

About.propTypes = {
  location: PropTypes.object,
};

export default About;
